<template>
    <div>
        <div class="content-operate">
            <h3>文章</h3>
            <el-button type="primary" @click="toArticleAdd">新增</el-button>
        </div>
        <div class="content-list">
            <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="ID"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="title"
                        label="标题"
                        width="280">
                </el-table-column>

                <el-table-column property="status" align="center" label="上架状态" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status" class="status">上架中</span>
                        <span v-else="scope.row.status" class="status">已下架</span>
                        <el-switch active-color="#13ce66" inactive-color="#C0CCDA" v-model="scope.row.status" @change=sale(scope.row)>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="time"
                        label="发布时间"
                        width="280">
                </el-table-column>
                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <span class="operate-btn">编辑</span>
                        <span class="operate-btn" @click="del(scope.row.id)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

</template>

<script>
    // import ("@/assets/js/time.js");
    export default {
        data() {
            return {
                tableData: []
            }
        },
        mounted:function(){
            this.getArticleList();
        },methods:{
            del:function(id){
                let that = this;
                that.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const formData = new FormData();
                    formData.append('id',id);
                    that.axios.post(that.common.httpUrl+'/v1/article/del',formData).then((res) => {
                        if (res.data.code==that.common.httpCode.succ){
                            that.getArticleList();
                        }else {
                            that.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        that.$message({
                            message: "请求失败！",
                            type: 'error'
                        });
                    });
                }).catch(() => {
                });
            },
            sale:function(row){
                let that = this;
                const formData = new FormData();
                formData.append('id',row.id);
                formData.append('status',row.status?1:0);
                that.axios.post(that.common.httpUrl+'/v1/article/status',formData).then((res) => {
                    if (res.data.code==that.common.httpCode.succ){
                        row.status  = row.status?true:false;
                    }else {
                        that.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                }).catch((err) => {
                    that.$message({
                        message: "请求失败！",
                        type: 'error'
                    });
                });


            },
            toArticleAdd:function(){
                this.$router.push("/article/add")
            },
            getArticleList:function () {
                this.axios.get(this.common.httpUrl+'/v1/article/list').then((res) => {
                    if (res.data.code==this.common.httpCode.succ){
                        this.tableData = [];
                        for (var i=0;i<res.data.data.length;i++){
                            this.tableData.push({
                                id:res.data.data[i].id,
                                time: this.common.timestampToTime(res.data.data[i].create_at),
                                title: res.data.data[i].title,
                                status: res.data.data[i].status==1?true:false
                            })
                        }
                    }else {
                        this.$message({
                            message: res.data.data,
                            type: 'error'
                        });
                    }
                }).catch(() => {
                    this.$message({
                        message: "请求失败！",
                        type: 'error'
                    });
                });
            }
        }
    }
</script>
<style lang="scss">
    /*.content-title{*/
    /*    text-align: left;*/
    /*    padding: 20px;*/
    /*}*/

    .content-operate {
        margin-top: 20px;
        height: 60px;
    }
    .content-operate h3{
        float: left;
        /*width: 100px;*/
        /*display: inline;*/
        /*text-align: left!important;*/
        margin-left: 20px;
    }
    .content-operate .el-button{
        float: right;
        margin-right: 20px;
    }
    .status{
        margin-right: 20px;
    }

    .content-list{
        /*margin-top: 20px;*/
        padding: 20px;
    }

    .operate-btn{
        margin-left: 20px;
        color: #20a0ff;
        cursor: pointer;
    }


</style>